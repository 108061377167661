import axios from 'axios'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IGetAdvisorRequest,
  IInsertUpdateSPAApiRes,
  ISPAGetResponce,
  IInsertUpdateSPAApiReq,
  IUpdateSPAStatusApiReq,
  IInsertSPAAttachmentsApiReq,
  IGeneratePDFSPAApiRes,
  ICustodianName,
  IValidationMessage,
  ISPAError,
  IGenerateAccountNumberApiRes,
  IGetCusipSequenceNumberApiRes,
  IGetAdvisorSecurityByCusipApiRes,
  IGetAdvisorAccountsByClientIdApiRes,
  IFundAdminResp,
  IInsertUpdateSuppressMessageApiRes,
  IInsertUpdateSuppressMessageApiReq,
  ISuppressMessages,
  IInsertUpdateErrorCommentApiReq,
  IInsertUpdateErrorCommentApiRes,
  IErrorComment
} from './types'

const fetchExistingSPAService = (
  req: IGetAdvisorRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/GetAdvisorRequest`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<ISPAGetResponce>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertUpdateSPAService = (
  req: IInsertUpdateSPAApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/setAdvisorRequest`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateSPAApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const updateSPAStatusService = (
  req: IUpdateSPAStatusApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/updateAdvisorRequestStatus`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .put<IInsertUpdateSPAApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const insertSPAAttachmentsService = (
  req: IInsertSPAAttachmentsApiReq,
  options: IApiOptions
) => {
  const data = new FormData()
  if (req.attachments) {
    req.attachments.map((val: any) => {
      data.append('attachments', val[0])
    })
  }
  data.append('requestID', req.requestID?.toString() ?? '')
  const url = `${options.apiRoot}/cdm/SPA/spaUploadAttachments`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateSPAApiRes>(url, data, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchSPAAttachmentsService = (
  requestID: number,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/GetSpaAttachmentNames?requestID=${requestID}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const deleteSPAAttachmentsService = (
  fileName: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/DeleteSpaAttachments?fileName=${fileName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .delete<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const downloadSPADocService = (
  attachmentsURL: string,
  fileName: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/DownloadSpaAttachments?attachmentsURL=${attachmentsURL}&fileName=${fileName}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<string>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const generatePDFSPAService = (requestID: number, options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/SPA/PDFGenerate?requestID=${requestID}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGeneratePDFSPAApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const generateAccountNumberService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/spa/GetAccountSequenceNumber`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGenerateAccountNumberApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchSPACusipSequenceNumberService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/SPA/GetCusipSequenceNumber`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetCusipSequenceNumberApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAdvisorSecurityByCusipService = (
  cusip: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/GetAdvisorSecurities?Cusip=${cusip}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetAdvisorSecurityByCusipApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAdvisorAccountsByClientIdService = (
  clientId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/GetAdvisorAccounts?clientId=${clientId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IGetAdvisorAccountsByClientIdApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchSPACustodianNamesService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/SPA/GetCustodianNames`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ICustodianName[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const validateSPAApiService = (
  req: IInsertUpdateSPAApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/IsValidateSPAApi`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IValidationMessage[]>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const SPAErrorService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/SPA/GetSpaErrors`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<ISPAError[]>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchGetFundAdminsService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/SPA/GetFundAdmins`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IFundAdminResp>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchSuppressMessages = (errorMessage: string, options: IApiOptions) => {
  if (
    errorMessage !== null &&
    errorMessage !== undefined &&
    errorMessage !== ''
  ) {
    const url = `${options.apiRoot}/cdm/SPA/GetSuppressErrorMessages?errorMessage=${errorMessage}`
    const headers = {
      Authorization: `Bearer ${options.accessToken}`
    }
    return axios
      .get<ISuppressMessages[]>(url, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) => res.data)
  } else {
    const url = `${options.apiRoot}/cdm/SPA/GetSuppressErrorMessages`
    const headers = {
      Authorization: `Bearer ${options.accessToken}`
    }
    return axios
      .get<ISuppressMessages[]>(url, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) => res.data)
  }
}

const insertUpdateSuppressMessage = (
  req: IInsertUpdateSuppressMessageApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/SetSuppressErrorMessage`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateSuppressMessageApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

// Error Comments - GET
const fetchErrorComments = (options: IApiOptions, errorId?: string) => {
  if (errorId !== null && errorId !== undefined) {
    const url = `${options.apiRoot}/cdm/SPA/GetErrorComments?errorId=${errorId}`
    const headers = {
      Authorization: `Bearer ${options.accessToken}`
    }
    return axios
      .get<IErrorComment[]>(url, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) => res.data)
  } else {
    const url = `${options.apiRoot}/cdm/SPA/GetErrorComments`
    const headers = {
      Authorization: `Bearer ${options.accessToken}`
    }
    return axios
      .get<IErrorComment[]>(url, {
        headers,
        cancelToken: options.cancelToken
      })
      .then((res) => res.data)
  }
}

// Error Comment - Set
const setErrorComments = (
  req: IInsertUpdateErrorCommentApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/SPA/SetErrorComments`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IInsertUpdateErrorCommentApiRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

export {
  fetchExistingSPAService,
  insertUpdateSPAService,
  updateSPAStatusService,
  insertSPAAttachmentsService,
  fetchSPAAttachmentsService,
  downloadSPADocService,
  deleteSPAAttachmentsService,
  generatePDFSPAService,
  fetchSPACustodianNamesService,
  validateSPAApiService,
  SPAErrorService,
  generateAccountNumberService,
  fetchSPACusipSequenceNumberService,
  fetchAdvisorSecurityByCusipService,
  fetchAdvisorAccountsByClientIdService,
  fetchGetFundAdminsService,
  insertUpdateSuppressMessage,
  fetchSuppressMessages,
  fetchErrorComments,
  setErrorComments
}
