import { IAccount } from 'api/account.types'
import { ISearchParams, ISearchResult } from 'api/common.types'
import { OdataFilterOperatorEnum, OdataPropertyFilterGroup } from 'api/odata'
import { search } from 'api/search'
import { ISecurity } from 'api/security.types'
import { ISPAError } from 'api/spaerror.types'
import { get } from 'lodash'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { exportDataToExcel } from 'shared/xlsx'
import { getRockefellerApiOptions } from 'store/shared'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import {
  deleteSPAAttachmentsService,
  downloadSPADocService,
  fetchExistingSPAService,
  fetchSPAAttachmentsService,
  fetchSPACusipSequenceNumberService,
  fetchAdvisorSecurityByCusipService,
  fetchSPACustodianNamesService,
  generateAccountNumberService,
  generatePDFSPAService,
  insertSPAAttachmentsService,
  insertUpdateSPAService,
  SPAErrorService,
  updateSPAStatusService,
  validateSPAApiService,
  fetchAdvisorAccountsByClientIdService,
  fetchGetFundAdminsService,
  insertUpdateSuppressMessage,
  fetchSuppressMessages,
  setErrorComments,
  fetchErrorComments
} from '../../SPAAdvisor/api/specialtyAssetService'
import {
  IInsertSPAAttachmentsApiReq,
  IUpdateSPAStatusApiReq
} from '../api/types'
import {
  SPAStatus,
  getEnumKeyByEnumValue,
  getSPAErrorData
} from '../features/common/Utility'
import {
  accountSearchActions,
  clientSearchActions,
  custodianNameActions,
  deleteSPADocActions,
  downloadSPADocActions,
  fetchExistingSPAActions,
  fetchSPAAttachmentsActions,
  generatePDFSPAActions,
  insertSPAAttachmentsActions,
  insertUpdateSPAActions,
  errorSPAActions,
  securitySearchActions,
  updateSPAStatusActions,
  validateSPAActions,
  exportSPAToExcelActions,
  exportSPAErrorToExcelActions,
  generateAccountNumberActions,
  fetchCusipSequenceNumberActions,
  fetchAdvisorSecurityByCusipActions,
  errorSearchActions,
  fetchAdvisorAccountsByClientIdActions,
  fetchFundAdminsActions,
  fetchSuppressMessageActions,
  insertUpdateSuppressMessageActions,
  errorMessageSearchActions,
  setErrorCommentsActions,
  fetchErrorCommentsActions,
  exportSuppressMessageToExcelActions
} from './actions'
import { b64toBlob } from './utility'

function* handleFetchExistingSPA(
  action: ReturnType<typeof fetchExistingSPAActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchExistingSPAService(action.payload, options)
    )
    if (response && response?.data) {
      yield put(fetchExistingSPAActions.success(response))
    } else {
      yield put(
        fetchExistingSPAActions.failure(
          new Error('unable to fetch dashboard list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchExistingSPAActions.failure(e))
  }
}

function* handleInsertUpdateSPA(
  action: ReturnType<typeof insertUpdateSPAActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      insertUpdateSPAService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      yield put(insertUpdateSPAActions.success(apiResp))
      try {
        if (action.payload.attachments?.length ?? 0 > 0) {
          const payload: IInsertSPAAttachmentsApiReq = {
            requestID: apiResp.outputResponse,
            attachments: action.payload.attachments
          }
          const fileApiResp = yield* call(() =>
            insertSPAAttachmentsService(payload, options)
          )
          if (fileApiResp.errorCode === 0) {
            yield put(insertSPAAttachmentsActions.success(fileApiResp))
          } else {
            yield put(
              insertSPAAttachmentsActions.failure(
                new Error(
                  fileApiResp?.errormessage ||
                    'Unable to upload attachment(s), please try after sometime.'
                )
              )
            )
            return
          }
        }
      } catch (e: any) {
        yield put(
          insertSPAAttachmentsActions.failure(
            new Error(
              'Unable to upload attachment(s), please try after sometime.'
            )
          )
        )
        return
      }
      try {
        if (
          action.payload.btnStatus === 'S' ||
          action.payload.btnStatus === 'CS'
        ) {
          // const apiValidateResp = yield* call(() =>
          //   validateSPAApiService(action.payload, options)
          // )
          // if (!apiValidateResp.some((x) => x.error === null)) {
          //   yield put(validateSPAActions.success(apiValidateResp))
          //   yield put(updateSPAStatusActions.success({ msg: '' }))
          //   return
          // } else {
          //   validateSPAActions.clear()
          // }
          const payload: IUpdateSPAStatusApiReq = {
            requestID: apiResp.outputResponse,
            requestStatus: getEnumKeyByEnumValue(
              SPAStatus,
              action.payload.btnStatus === 'CS'
                ? SPAStatus.DRAFT
                : SPAStatus.PNDRVW
            )
          }
          const statusApiResp = yield* call(() =>
            updateSPAStatusService(payload, options)
          )
          if (statusApiResp.errorCode === 0) {
            yield put(
              updateSPAStatusActions.success({
                ...statusApiResp,
                msg: 'Successfully submitted the request'
              })
            )
          } else {
            yield put(
              updateSPAStatusActions.failure(
                new Error(
                  statusApiResp?.errormessage ||
                    'Unable to update Specialty Asset Status, please try after sometime.'
                )
              )
            )
          }
        }
      } catch (e: any) {
        yield put(
          updateSPAStatusActions.failure(
            new Error(
              'Unable to update Specialty Asset Status, please try after sometime.'
            )
          )
        )
      }
    } else {
      yield put(
        insertUpdateSPAActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to insert/update Specialty Asset Form, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      insertUpdateSPAActions.failure(
        new Error(
          e?.response?.data?.msg ||
            'Unable to insert/update Specialty Asset Form, please try after sometime.'
        )
      )
    )
  }
}

function* handleUpdateSPAStatus(
  action: ReturnType<typeof updateSPAStatusActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      updateSPAStatusService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      const reqStatus =
        action.payload?.requestStatus &&
        Object.values(SPAStatus)[
          Object.keys(SPAStatus).indexOf(action.payload?.requestStatus?.trim())
        ]
      const msg =
        reqStatus === SPAStatus.PNDRVW
          ? 'Successfully submitted the request'
          : reqStatus === SPAStatus.RETRND
          ? 'Successfully request Return to Advisor'
          : 'Successfully Approved the request'
      const resp = { ...apiResp, msg: msg }
      yield put(updateSPAStatusActions.success(resp))
    } else {
      yield put(
        updateSPAStatusActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to update Specialty Asset Status, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      updateSPAStatusActions.failure(
        new Error(
          'Unable to update Specialty Asset Status, please try after sometime.'
        )
      )
    )
  }
}

function* handleInsertSPAAttachments(
  action: ReturnType<typeof insertSPAAttachmentsActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      insertSPAAttachmentsService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      yield put(insertSPAAttachmentsActions.success(apiResp))
    } else {
      yield put(
        insertSPAAttachmentsActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to save attachemnts, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      insertSPAAttachmentsActions.failure(
        new Error('Unable to save attachments, please try after sometime.')
      )
    )
  }
}

function* handleClientSearch(
  action: ReturnType<typeof clientSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      search(
        'client',
        {
          query: action.payload,
          fullQuery: true,
          searchFields: ['ClientAdvisorID', 'LegalEntityName'],
          select: [
            'ClientAdvisorID',
            'ClientAdvisor',
            'ClientAdvisorTeam',
            'LegalEntityName',
            'LegalEntityID',
            'ClientKPI/AumTotal',
            'loginDetails',
            'Account',
            'srcClientNumber',
            'id',
            'PartyUniqueId',
            'qualifiedClient',
            'TrustCompany'
          ],
          count: true,
          top: 500
        },
        options
      )
    )
    if (response && response?.value) {
      yield put(clientSearchActions.success(response))
    } else {
      yield put(
        clientSearchActions.failure(new Error('unable to fetch client list'))
      )
    }
  } catch (e: any) {
    yield put(clientSearchActions.failure(e))
  }
}

function* handleSecuritySearch(
  action: ReturnType<typeof securitySearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const filter: OdataPropertyFilterGroup = {
      and: [
        {
          operator: OdataFilterOperatorEnum.searchin,
          path: 'l4code',
          type: 'string',
          value: action.payload.l4CodesList
        }
      ]
    }
    const params: ISearchParams = {
      top: 500,
      count: true,
      query: action.payload.searchText || 'rockefeller',
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'securitydescription', direction: 'asc' }
      ],
      filters: [filter]
    }
    const response: ISearchResult<ISecurity> = yield call(
      search,
      'security' as const,
      {
        ...params,
        searchFields: ['securitydescription', 'cusip'],
        select: [
          'id',
          'cusip',
          'sedol',
          'ticker',
          'securitydescription',
          'l1code',
          'l2code',
          'l3code',
          'l4code',
          'l1description',
          'l2description',
          'l3description',
          'l4description',
          'minorAssetType'
        ]
      },
      options
    )
    if (response && response?.value) {
      yield put(securitySearchActions.success(response))
    } else {
      yield put(
        securitySearchActions.failure(
          new Error('unable to fetch security list')
        )
      )
    }
  } catch (e: any) {
    yield put(securitySearchActions.failure(e))
  }
}

function* handleAccountSearch(
  action: ReturnType<typeof accountSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const filter: OdataPropertyFilterGroup = {
      and: [
        {
          operator: OdataFilterOperatorEnum.eq,
          path: 'LegalEntityID',
          type: 'string',
          value: action.payload.LegalEntityID
        },
        {
          operator: OdataFilterOperatorEnum.eq,
          path: 'ClientAdvisorID',
          type: 'string',
          value: action.payload.ClientAdvisorID
        },
        {
          operator: OdataFilterOperatorEnum.eq,
          path: 'accountStatus',
          type: 'string',
          value: 'Open'
        }
      ]
    }
    const params: ISearchParams = {
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'id', direction: 'desc' }
      ],
      filters: [filter]
    }

    const response: ISearchResult<IAccount> = yield call(
      search,
      'account' as const,
      {
        ...params,
        select: [
          'accountId',
          'ClientAdvisorID',
          'ClientAdvisor',
          'ClientAdvisorTeam',
          'LegalEntityName',
          'LegalEntityID',
          'CustodianName',
          'CustodianType',
          'CustodyAccount',
          'CustodianCode',
          'Accountsource'
        ]
      },
      options
    )
    if (response && response?.value) {
      yield put(accountSearchActions.success(response))
    } else {
      yield put(
        accountSearchActions.failure(new Error('unable to fetch account list'))
      )
    }
  } catch (e: any) {
    yield put(accountSearchActions.failure(e))
  }
}

// Used in Suppressed dashboard
function* handleMessageErrorSearch() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    const params: ISearchParams = {
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'id', direction: 'desc' }
      ]
    }

    const response: ISearchResult<ISPAError> = yield call(
      search,
      'spaerror' as const,
      {
        ...params,
        select: ['ErrorID', 'sourceName', 'Message', 'LastUpdatedAt'],
        facets: ['Message, count:1000'],
        top: 0
      },
      options
    )

    if (response && response.value) {
      yield put(errorMessageSearchActions.success(response))
    } else {
      yield put(
        errorMessageSearchActions.failure(
          new Error('unable to fetch error list')
        )
      )
    }
  } catch (e: any) {
    yield put(errorMessageSearchActions.failure(e))
  }
}

function* handleErrorSearch(
  action: ReturnType<typeof errorSearchActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)

    let response: ISearchResult<ISPAError>
    if (action.payload === 'MessageErrorSearch') {
      const params: ISearchParams = {
        orderBy: [
          { dataPath: 'search.score()', direction: 'desc' },
          { dataPath: 'id', direction: 'desc' }
        ]
      }

      response = yield call(
        search,
        'spaerror' as const,
        {
          ...params,
          select: ['ErrorID', 'sourceName', 'Message', 'LastUpdatedAt'],
          facets: ['Message, count:1000'],
          top: 0
        },
        options
      )
    } else {
      const filter: OdataPropertyFilterGroup = {
        and: [
          {
            operator: OdataFilterOperatorEnum.searchin,
            path: 'sourceName',
            type: 'string',
            value: action.payload.sourceName ?? []
          },
          {
            operator: OdataFilterOperatorEnum.searchin,
            path: 'wave',
            type: 'string',
            value: action.payload.wave ?? []
          }
        ]
      }
      const params: ISearchParams = {
        orderBy: [
          { dataPath: 'search.score()', direction: 'desc' },
          { dataPath: 'id', direction: 'desc' }
        ],
        filters:
          action.payload.sourceName && action.payload.wave ? [filter] : []
      }

      response = yield call(
        search,
        'spaerror' as const,
        {
          ...params,
          select: [
            'ErrorID',
            'entity',
            'AccountNumber',
            'cusip',
            'ReferanceAccount',
            'CustodianCusip',
            'ownedId',
            'sourceSecurityID',
            'OwnerId',
            'symbol',
            'tradeDate',
            'sourceName',
            'Age',
            'ErrorFlag',
            'Message',
            'LastUpdatedAt',
            'status'
          ],
          facets: ['sourceName', 'wave'],
          top: 0
        },
        options
      )
    }

    if (response && response?.value) {
      yield put(errorSearchActions.success(response))
    } else {
      yield put(
        errorSearchActions.failure(new Error('unable to fetch error list'))
      )
    }
  } catch (e: any) {
    yield put(errorSearchActions.failure(e))
  }
}

function* handleFetchSuppressMessage(
  action: ReturnType<typeof fetchSuppressMessageActions.request>
) {
  try {
    // yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchSuppressMessages(action.payload, options)
    )

    if (response.length) {
      yield put(fetchSuppressMessageActions.success(response))
    } else {
      yield put(
        fetchSuppressMessageActions.failure(
          new Error('Unable to get suppressed messages')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchSuppressMessageActions.failure(e))
  }
}

function* handleInsertUpdateSuppressMessage(
  action: ReturnType<typeof insertUpdateSuppressMessageActions.request>
) {
  try {
    // yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      insertUpdateSuppressMessage(action.payload, options)
    )
    if (response.errorCode === 0) {
      yield put(insertUpdateSuppressMessageActions.success(response))
    } else {
      yield put(
        insertUpdateSuppressMessageActions.failure(
          new Error('Unable to set suppress messages')
        )
      )
    }
  } catch (e: any) {
    yield put(insertUpdateSuppressMessageActions.failure(e))
  }
}

function* handleFetchSPAAttachments(
  action: ReturnType<typeof fetchSPAAttachmentsActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchSPAAttachmentsService(action.payload, options)
    )
    if (response && response) {
      yield put(fetchSPAAttachmentsActions.success(response))
    } else {
      yield put(
        fetchSPAAttachmentsActions.failure(
          new Error('unable to fetch attachments list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchSPAAttachmentsActions.failure(e))
  }
}

function* handleDownloadSPA(
  action: ReturnType<typeof downloadSPADocActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: string = yield* call(() =>
      downloadSPADocService(
        action.payload.attachmentsURL,
        action.payload.fileName,
        options
      )
    )
    yield put(downloadSPADocActions.success())
    const blob = b64toBlob(data)
    const fileURL = URL.createObjectURL(blob)
    window.open(fileURL, 'DocumentWindow')
  } catch (e: any) {
    yield put(downloadSPADocActions.failure(e))
  }
}

function* handleDeleteSPA(
  action: ReturnType<typeof deleteSPADocActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data: string = yield* call(() =>
      deleteSPAAttachmentsService(action.payload.fileName, options)
    )
    if (data) {
      yield put(deleteSPADocActions.success())
    } else {
      yield put(
        deleteSPADocActions.failure(new Error('unable to delete attachment'))
      )
    }
  } catch (e: any) {
    yield put(
      deleteSPADocActions.failure(
        new Error('Unable to delete attachment, please try after sometime.')
      )
    )
  }
}

function* handleGeneratePDFSPA(
  action: ReturnType<typeof generatePDFSPAActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      generatePDFSPAService(action.payload, options)
    )
    if (response.errorCode === 0) {
      yield put(generatePDFSPAActions.success(response))
    } else {
      yield put(
        generatePDFSPAActions.failure(new Error('Unable to generate PDF'))
      )
    }
  } catch (e: any) {
    yield put(generatePDFSPAActions.failure(e))
  }
}
function* handleGenerateAccountNumber(
  action: ReturnType<typeof generateAccountNumberActions.request>
) {
  try {
    console.log('action', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => generateAccountNumberService(options))
    if (response.success) {
      yield put(generateAccountNumberActions.success(response.data[0]))
    } else {
      yield put(
        generateAccountNumberActions.failure(
          new Error('Unable to generate Account Number')
        )
      )
    }
  } catch (e: any) {
    yield put(generateAccountNumberActions.failure(e))
  }
}
function* handleSPACusipSequenceNumber(
  action: ReturnType<typeof fetchCusipSequenceNumberActions.request>
) {
  try {
    console.log('SPACusip', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchSPACusipSequenceNumberService(options)
    )
    if (response.success) {
      yield put(fetchCusipSequenceNumberActions.success(response.data[0]))
    } else {
      yield put(
        fetchCusipSequenceNumberActions.failure(
          new Error('Unable to get Cusip Sequence Number')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchCusipSequenceNumberActions.failure(e))
  }
}

function* handleGetAdvisorSecurityByCusip(
  action: ReturnType<typeof fetchAdvisorSecurityByCusipActions.request>
) {
  try {
    console.log('AdvisorSecurityByCusip - action', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAdvisorSecurityByCusipService(action.payload, options)
    )
    if (response.success) {
      console.log('AdvisorSecurityByCusip - response', response)
      yield put(fetchAdvisorSecurityByCusipActions.success(response))
    } else {
      yield put(
        fetchAdvisorSecurityByCusipActions.failure(
          new Error('Unable to get advisor security by Cusip')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAdvisorSecurityByCusipActions.failure(e))
  }
}

function* handleAdvisorAccountsByClientId(
  action: ReturnType<typeof fetchAdvisorAccountsByClientIdActions.request>
) {
  try {
    console.log('AdvisorAccountsByClientId', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAdvisorAccountsByClientIdService(action.payload, options)
    )
    if (response.success) {
      yield put(fetchAdvisorAccountsByClientIdActions.success(response))
    } else {
      yield put(
        fetchAdvisorAccountsByClientIdActions.failure(
          new Error('Unable to get advisor account by client ID')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAdvisorAccountsByClientIdActions.failure(e))
  }
}

//Get - Error Comments
function* handleFetchErrorComments(
  action: ReturnType<typeof fetchErrorCommentsActions.request>
) {
  try {
    // yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchErrorComments(options, action.payload)
    )

    if (response && response) {
      yield put(fetchErrorCommentsActions.success(response))
    } else {
      yield put(
        fetchErrorCommentsActions.failure(
          new Error('unable to fetch error comments')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchErrorCommentsActions.failure(e))
  }
}

function* handleInsertUpdateErrorComments(
  action: ReturnType<typeof setErrorCommentsActions.request>
) {
  try {
    // yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      setErrorComments(action.payload, options)
    )
    if (response.errorCode === 0) {
      yield put(setErrorCommentsActions.success(response))
    } else {
      yield put(
        setErrorCommentsActions.failure(
          new Error('Unable to set error comments')
        )
      )
    }
  } catch (e: any) {
    yield put(insertUpdateSuppressMessageActions.failure(e))
  }
}

function* handleFetchSPACustodianNames(
  action: ReturnType<typeof custodianNameActions.request>
) {
  try {
    console.log('action', action)
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchSPACustodianNamesService(options))
    if (response && response) {
      yield put(custodianNameActions.success(response))
    } else {
      yield put(
        custodianNameActions.failure(
          new Error('unable to fetch Custodian Names')
        )
      )
    }
  } catch (e: any) {
    yield put(custodianNameActions.failure(e))
  }
}

function* handleValidateSPA(
  action: ReturnType<typeof validateSPAActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      validateSPAApiService(action.payload, options)
    )
    if (apiResp) {
      yield put(validateSPAActions.success(apiResp))
    }
  } catch (e: any) {
    yield put(validateSPAActions.failure(e))
  }
}

function* handleSPAErrors(action: ReturnType<typeof errorSPAActions.request>) {
  try {
    console.log('action', action)
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() => SPAErrorService(options))
    if (apiResp) {
      yield put(errorSPAActions.success(getSPAErrorData(apiResp)))
    }
  } catch (e: any) {
    yield put(errorSPAActions.failure(e))
  }
}

function* handleExportSPAToExcel(
  action: ReturnType<typeof exportSPAToExcelActions.request>
) {
  try {
    const { spaSummary, columns } = action.payload
    const filename = 'SPA_Summary.xlsx'
    const wsName = 'Fees'
    const data = spaSummary?.map((plan) => {
      const excelPlan = {
        ...plan
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })
    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )
    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportSPAToExcelActions.success())
  } catch (e: any) {
    yield put(exportSPAToExcelActions.failure(e))
  }
}

function* handleExportSPAErrorToExcel(
  action: ReturnType<typeof exportSPAErrorToExcelActions.request>
) {
  try {
    const { spaError, columns } = action.payload
    const filename = 'SPA_Error.xlsx'
    const wsName = 'Error'
    const data = spaError?.map((plan) => {
      const excelPlan = {
        ...plan
      }
      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })
    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )
    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportSPAErrorToExcelActions.success())
  } catch (e: any) {
    yield put(exportSPAErrorToExcelActions.failure(e))
  }
}

function* handleGetFundAdminsActions() {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchGetFundAdminsService(options))
    if (response.success) {
      yield put(fetchFundAdminsActions.success(response?.data))
    } else {
      yield put(
        fetchFundAdminsActions.failure(new Error('Unable to get Fund Admin'))
      )
    }
  } catch (e: any) {
    yield put(fetchFundAdminsActions.failure(e))
  }
}

function* handleExportSuppressMessageToExcel(
  action: ReturnType<typeof exportSuppressMessageToExcelActions.request>
) {
  try {
    const { suppressMessages, columns } = action.payload
    const filename = 'Suppress_Messages.xlsx'
    const wsName = 'Fees'
    const data = suppressMessages?.map((plan) => {
      const excelPlan = {
        ...plan
      }

      return columns?.map((column): unknown =>
        column.fieldName ? get(excelPlan, column.fieldName) : ''
      )
    })

    data?.unshift(
      columns?.filter((x) => x.key !== 'details')?.map((x) => x.name)
    )
    yield call(() =>
      exportDataToExcel(
        {
          sheets: [{ name: wsName, data: data || [] }]
        },
        filename
      )
    )
    yield put(exportSuppressMessageToExcelActions.success())
  } catch (e: any) {
    yield put(exportSuppressMessageToExcelActions.failure(e))
  }
}

export const SPAsagas = [
  () => takeLatest(insertUpdateSPAActions.request, handleInsertUpdateSPA),
  () => takeLatest(clientSearchActions.request, handleClientSearch),
  () => takeLatest(securitySearchActions.request, handleSecuritySearch),
  () => takeLatest(fetchExistingSPAActions.request, handleFetchExistingSPA),
  () => takeLatest(updateSPAStatusActions.request, handleUpdateSPAStatus),
  () =>
    takeLatest(insertSPAAttachmentsActions.request, handleInsertSPAAttachments),
  () => takeLatest(accountSearchActions.request, handleAccountSearch),
  () =>
    takeLatest(fetchSPAAttachmentsActions.request, handleFetchSPAAttachments),
  () => takeLatest(downloadSPADocActions.request, handleDownloadSPA),
  () => takeLatest(deleteSPADocActions.request, handleDeleteSPA),
  () => takeLatest(generatePDFSPAActions.request, handleGeneratePDFSPA),
  () =>
    takeLatest(
      generateAccountNumberActions.request,
      handleGenerateAccountNumber
    ),
  () =>
    takeLatest(
      fetchCusipSequenceNumberActions.request,
      handleSPACusipSequenceNumber
    ),
  () =>
    takeLatest(
      fetchAdvisorSecurityByCusipActions.request,
      handleGetAdvisorSecurityByCusip
    ),
  () =>
    takeLatest(
      fetchAdvisorAccountsByClientIdActions.request,
      handleAdvisorAccountsByClientId
    ),
  () => takeLatest(custodianNameActions.request, handleFetchSPACustodianNames),
  () => takeLatest(validateSPAActions.request, handleValidateSPA),
  () => takeLatest(errorSPAActions.request, handleSPAErrors),
  () => takeLatest(exportSPAToExcelActions.request, handleExportSPAToExcel),
  () =>
    takeLatest(
      exportSPAErrorToExcelActions.request,
      handleExportSPAErrorToExcel
    ),
  () => takeLatest(errorSearchActions.request, handleErrorSearch),
  () => takeLatest(fetchFundAdminsActions.request, handleGetFundAdminsActions),
  () =>
    takeLatest(fetchSuppressMessageActions.request, handleFetchSuppressMessage),
  () =>
    takeLatest(
      insertUpdateSuppressMessageActions.request,
      handleInsertUpdateSuppressMessage
    ),
  () => takeLatest(fetchErrorCommentsActions.request, handleFetchErrorComments),
  () =>
    takeLatest(
      setErrorCommentsActions.request,
      handleInsertUpdateErrorComments
    ),
  () => {
    return takeLatest(
      errorMessageSearchActions.request,
      handleMessageErrorSearch
    )
  },
  () => {
    return takeLatest(
      exportSuppressMessageToExcelActions.request,
      handleExportSuppressMessageToExcel
    )
  }
]
