import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getErrorAccountSearchState,
  getErrorMessageSearchState
} from 'modules/SPAAdvisor/store/selectors'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { selectors as errorSelectors } from './index'

export interface IErrorSummaryState {
  sourceNames: string[]
  waves: string[]
  selectedSourceNames: string[]
  selectedErrorWaves: string[]
  selectedErrorMessages: string[]
}

export const initialState = {
  sourceNames: [],
  waves: [],
  selectedSourceNames: [],
  selectedErrorWaves: [],
  selectedErrorMessages: []
} as IErrorSummaryState

export const { actions: errorsSummaryActions, reducer: errorsSummaryReducer } =
  createSlice({
    name: '@modules/@SPA/@advisor/@SPAErrorsSummary',
    initialState,
    reducers: {
      setSourceNames: (state, action: PayloadAction<string[]>) => ({
        ...state,
        sourceNames: action.payload
      }),
      setWaves: (state, action: PayloadAction<string[]>) => ({
        ...state,
        waves: action.payload
      }),
      setSelectedSourceNames: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedSourceNames: action.payload
      }),
      setSelectedErrorWaves: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedErrorWaves: action.payload
      }),
      setSelectedErrorMessages: (state, action: PayloadAction<string[]>) => ({
        ...state,
        selectedErrorMessages: action.payload
      })
    }
  })

const rootSelector = (state: AppState) => state.modules.SPA.SPAErrorsSummary

export const useSPAErrorsStore = () => {
  const dispatch = useDispatch()
  const {
    sourceNames,
    waves,
    selectedErrorWaves,
    selectedSourceNames,
    selectedErrorMessages
  } = useSelector(rootSelector)
  const hasSelectedFilters = useMemo(
    () => selectedSourceNames.length > 0 && selectedErrorWaves.length > 0,
    [selectedErrorWaves.length, selectedSourceNames.length]
  )

  const spaErrorSearchResults = useSelector(getErrorAccountSearchState)
    ?.errorSearchList?.['@search.facets']

  const spaErrorMessageSearchResults = useSelector(getErrorMessageSearchState)
  const sourceNameFacets = useMemo(
    () =>
      spaErrorSearchResults?.sourceName?.reduce((acc, item) => {
        return {
          ...acc,
          [item?.value]: item?.count ?? 0
        }
      }, {}) as Record<string, number>,
    [spaErrorSearchResults?.sourceName]
  )
  const waveFacets = useMemo(
    () =>
      spaErrorSearchResults?.wave?.reduce((acc, item) => {
        return {
          ...acc,
          [item?.value]: item?.count ?? 0
        }
      }, {}) as Record<string, number>,
    [spaErrorSearchResults?.wave]
  )

  const messageFacets = useMemo(() => {
    if (spaErrorMessageSearchResults?.inProgress === false) {
      return spaErrorMessageSearchResults?.errorSearchList?.[
        '@search.facets'
      ]?.Message?.reduce((acc, item) => {
        return {
          ...acc,
          [item?.value]: item?.count ?? 0
        }
      }, {}) as Record<string, number>
    } else {
      return {} as Record<string, number>
    }
  }, [spaErrorMessageSearchResults])
  const spaErrorFilters = useSelector(errorSelectors.uiSelectors.getFilters)
  const selectedSourceNameFilters = useMemo(
    () => spaErrorFilters?.sourceName?.values,
    [spaErrorFilters?.sourceName?.values]
  )
  const selectedErrorWaveFilters = useMemo(
    () => spaErrorFilters?.wave?.values,
    [spaErrorFilters?.wave?.values]
  )
  const setWaves = useCallback(
    (payload: string[]) => {
      dispatch(errorsSummaryActions.setWaves(payload))
    },
    [dispatch]
  )

  const setSourceNames = useCallback(
    (payload: string[]) => {
      dispatch(errorsSummaryActions.setSourceNames(payload))
    },
    [dispatch]
  )

  const setSelectedErrorWaves = useCallback(
    (payload: string[]) => {
      dispatch(errorsSummaryActions.setSelectedErrorWaves(payload))
    },
    [dispatch]
  )

  const setSelectedSourceNames = useCallback(
    (payload: string[]) => {
      dispatch(errorsSummaryActions.setSelectedSourceNames(payload))
    },
    [dispatch]
  )

  return {
    sourceNames,
    setSourceNames,
    waves,
    setWaves,
    selectedErrorWaves,
    setSelectedErrorWaves,
    selectedSourceNames,
    setSelectedSourceNames,
    hasSelectedFilters,
    sourceNameFacets,
    waveFacets,
    messageFacets,
    selectedSourceNameFilters,
    selectedErrorWaveFilters,
    selectedErrorMessages
  }
}
