import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  ErrorID = 'ErrorID',
  entity = 'entity',
  AccountNumber = 'AccountNumber',
  cusip = 'cusip',
  ReferanceAccount = 'ReferanceAccount',
  CustodianCusip = 'CustodianCusip',
  ownedId = 'ownedId',
  sourceSecurityID = 'sourceSecurityID',
  ownerId = 'ownerId',
  symbol = 'symbol',
  tradeDate = 'tradeDate',
  sourceName = 'sourceName',
  wave = 'wave',
  Age = 'Age',
  ErrorFlag = 'ErrorFlag',
  Message = 'Message',
  Status = 'Status',
  LastUpdatedAt = 'LastUpdatedAt'
}

export const SPAErrorColumnIds = { ...ColumnIdsEnum }
export type SPAErrorColumnIds = typeof SPAErrorColumnIds

export const SPAErrorColumnDefinitions: Record<string, IColumnDefinition> = {
  [SPAErrorColumnIds.ErrorID]: {
    id: SPAErrorColumnIds.ErrorID,
    name: 'Error ID',
    type: 'string',
    width: 155,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ErrorID'
  },
  [SPAErrorColumnIds.entity]: {
    id: SPAErrorColumnIds.entity,
    name: 'Entity',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'entity'
  },
  [SPAErrorColumnIds.AccountNumber]: {
    id: SPAErrorColumnIds.AccountNumber,
    name: 'Acc No',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'AccountNumber'
  },
  [SPAErrorColumnIds.cusip]: {
    id: SPAErrorColumnIds.cusip,
    name: 'Cusip',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'cusip'
  },
  [SPAErrorColumnIds.ReferanceAccount]: {
    id: SPAErrorColumnIds.ReferanceAccount,
    name: 'Ref Acc',
    type: 'string',
    width: 90,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ReferanceAccount'
  },
  [SPAErrorColumnIds.CustodianCusip]: {
    id: SPAErrorColumnIds.CustodianCusip,
    name: 'Custodian Cusip',
    type: 'string',
    width: 140,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'CustodianCusip'
  },
  [SPAErrorColumnIds.ownedId]: {
    id: SPAErrorColumnIds.ownedId,
    name: 'Owned Id',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ownedId'
  },
  [SPAErrorColumnIds.sourceSecurityID]: {
    id: SPAErrorColumnIds.sourceSecurityID,
    name: 'Security Id',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'sourceSecurityID'
  },
  [SPAErrorColumnIds.symbol]: {
    id: SPAErrorColumnIds.symbol,
    name: 'Symbol',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'symbol'
  },
  [SPAErrorColumnIds.tradeDate]: {
    id: SPAErrorColumnIds.tradeDate,
    name: 'Trade Date',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'tradeDate'
  },
  [SPAErrorColumnIds.sourceName]: {
    id: SPAErrorColumnIds.sourceName,
    name: 'Source Name',
    type: 'string',
    width: 120,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'sourceName'
  },
  [SPAErrorColumnIds.wave]: {
    id: SPAErrorColumnIds.wave,
    name: 'Wave',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'wave'
  },
  [SPAErrorColumnIds.Age]: {
    id: SPAErrorColumnIds.Age,
    name: 'Age',
    type: 'string',
    width: 60,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'Age'
  },
  [SPAErrorColumnIds.ErrorFlag]: {
    id: SPAErrorColumnIds.ErrorFlag,
    name: 'Error Flag',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'ErrorFlag'
  },
  [SPAErrorColumnIds.Message]: {
    id: SPAErrorColumnIds.Message,
    name: 'Message',
    type: 'string',
    width: 220,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'Message',
    validFacetValues: []
  },
  [SPAErrorColumnIds.Status]: {
    id: SPAErrorColumnIds.Status,
    name: 'Comments',
    type: 'string',
    width: 300,
    facetable: true,
    dataPath: 'status'
  },
  [SPAErrorColumnIds.LastUpdatedAt]: {
    id: SPAErrorColumnIds.LastUpdatedAt,
    name: 'Updated Date',
    type: 'string',
    width: 110,
    filterable: false,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'LastUpdatedAt'
  }
}
